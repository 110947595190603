import React from "react"

import IconTranslation from "../images/icon-translation.svg"
import IconDocs from "../images/icon-docs.svg"
import IconLupe from "../images/icon-lupe.svg"

const Work = () => {
  return (
    <section id="leistungen">
      <div className="content-grid content-grid-work">
        <div className="content-item work-1">
          <img
            src={IconTranslation}
            alt=""
            className="content-icon "
            data-aos="fade-up"
          />
          <h2 className="content-title " data-aos="fade-up">
            Übersetzung
          </h2>
          <p className="content-paragraph " data-aos="fade-up">
            Italienisch - Deutsch, Deutsch - Italienisch <br />
            Englisch - Deutsch, Deutsch - Englisch
          </p>
          <p className="content-paragraph " data-aos="fade-up">
            Eine geglückte Übersetzung soll funktionieren und nicht als
            Übersetzung zu erkennen sein. Sie ist das Ergebnis von Wissen,
            Recherche und Kompetenz. Übersetzungen in die Fremdsprache werden
            auch immer noch von einem Muttersprachler kontrolliert. Hier setze
            ich auf das Vier-Augen-Prinzip und Diskretion für Übersetzer und
            Lektor sind selbstverständlich.
          </p>
        </div>
        <div className="content-item work-2">
          <img
            src={IconDocs}
            alt=""
            className="content-icon "
            data-aos="fade-up"
          />
          <h2 className="content-title " data-aos="fade-up">
            Texterstellung
          </h2>
          <p className="content-paragraph " data-aos="fade-up">
            Ich helfe Ihnen gerne, Ihre Ideen in Worte zu fassen und auf die
            jeweilige Zielgruppe anzupassen. Ebenso wie bei einer gelungenen
            Übersetzung, soll auch der Text wirken und funktionieren.
          </p>
        </div>
        <div className="content-item work-3 ">
          <img
            src={IconLupe}
            alt=""
            className="content-icon "
            data-aos="fade-up"
          />
          <h2 className="content-title " data-aos="fade-up">
            Lektorat & Korrektorat
          </h2>
          <p className="content-paragraph " data-aos="fade-up">
            Lektorat und Korrektorat sind weitere Sprachdienstleistungen, die
            ich Ihnen gerne anbiete. Grammatik, Orthographie und Interpunktion
            werden beim Korrektorat einer genauen Prüfung unterzogen. Beim
            Lektorat hingegen werden Stil und Inhalt begutachtet.
          </p>
        </div>
        <div className="content-line"></div>
      </div>
    </section>
  )
}

export default Work
