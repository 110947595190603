import React, { useState } from "react"

import LogoFooter from "../images/logo-footer.svg"
import Impressum from "./impressum"

const Footer = () => {
  const [impOpen, setImpOpen] = useState(false)
  return (
    <>
      <footer id="kontakt">
        <img src={LogoFooter} alt="" />
        <p>
          Da alle Texte verschieden sind, ist auch jedes Angebot
          unterschiedlich. Ich erstelle Ihr Angebot individuell nach Ihren
          Bedürfnissen.
        </p>
        <p className="contact">
          buero@alexandra-unterasinger.com
          <br />
          +43 (0) 660 2105490
        </p>
        <p className="impressum-trigger" onClick={() => setImpOpen(true)}>
          Impressum / Datenschutz
        </p>
      </footer>

      <Impressum impOpen={impOpen} setImpOpen={setImpOpen} />
    </>
  )
}

export default Footer
