import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Referenzen = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ref1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section id="referenzen">
      <div className="content-grid content-grid-work">
        <div className="content-item ref-1">
          <h2 className="content-title" data-aos="fade-up">
            Referenzen
          </h2>
        </div>
        <div className="content-item ref-2" data-aos="fade-up">
          <Img fluid={data.file.childImageSharp.fluid} />
        </div>
        <div className="content-line"></div>
      </div>
    </section>
  )
}

export default Referenzen
