import React from "react"

const Hamburger = ({ paths }) => {
  const { menuPath } = paths

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      height="15.5px"
      viewBox="0 0 39 15.5"
    >
      <path className="st0 menu-button" d={menuPath} />
    </svg>
  )
}

export default Hamburger
