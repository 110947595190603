import React from "react"
import closeIcon from "../images/close.svg"

const Impressum = ({ impOpen, setImpOpen }) => {
  let activeClass = impOpen ? " active" : ""
  return (
    <div className={"impressum-wrapper" + activeClass}>
      <div className="impressum-close" onClick={() => setImpOpen(false)}>
        <img src={closeIcon} alt="" />
      </div>
      <div className="impressum-inner">
        <h1>Impressum</h1>
        <p>
          Informationspflicht laut &sect;5 E-Commerce Gesetz, &sect;14
          Unternehmensgesetzbuch, &sect;63 Gewerbeordnung und
          Offenlegungspflicht laut &sect;25 Mediengesetz.
        </p>
        <p>
          Alexandra Unterasinger
          <br />
          Musterstrasse 1,
          <br />
          1234 Musterhausen,
          <br />
          &Ouml;sterreich
        </p>
        <p>
          <strong>Unternehmensgegenstand: </strong>
          &Uuml;bersetzung und Sprachdienstleistungen
        </p>
        <p>
          <strong>Tel.: </strong>
          01234/56789
          <br />
          <strong>Fax: </strong>
          01234/56789-0
          <br />
          <strong>E-Mail: </strong>
          <a href="mailto:office@musterfirma.com">office@musterfirma.com</a>
        </p>
        <p>
          <strong>Mitglied bei: </strong>
          UNIVERSITAS - Berufsverband f&uuml;r Dolmetschen und &Uuml;bersetzen
        </p>
        <p>
          <strong>Aufsichtsbeh&ouml;rde/Gewerbebeh&ouml;rde: </strong>
          BH Lienz
          <br />
          <strong>Verleihungsstaat: </strong>
          &Ouml;sterreich
        </p>
        <h2>Konzept, Design und Programmierung</h2>
        <p>
          <a
            href="http://www.aberjung.com/"
            rel="noopener noreferrer"
            target="_blank"
            className="external"
          >
            Aberjung GmbH
          </a>
        </p>

        <h2>EU-Streitschlichtung</h2>
        <p>
          Gem&auml;&szlig; Verordnung &uuml;ber Online-Streitbeilegung in
          Verbraucherangelegenheiten (ODR-Verordnung) m&ouml;chten wir Sie
          &uuml;ber die Online-Streitbeilegungsplattform (OS-Plattform)
          informieren.
          <br />
          Verbraucher haben die M&ouml;glichkeit, Beschwerden an die Online
          Streitbeilegungsplattform der Europ&auml;ischen Kommission unter
          <a
            href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://ec.europa.eu/odr?tid=221099081
          </a>
          zu richten. Die daf&uuml;r notwendigen Kontaktdaten finden Sie
          oberhalb in unserem Impressum.
        </p>
        <p>
          Wir m&ouml;chten Sie jedoch darauf hinweisen, dass wir nicht bereit
          oder verpflichtet sind, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <h2>Haftung f&uuml;r Inhalte dieser Webseite</h2>
        <p>
          Wir entwickeln die Inhalte dieser Webseite st&auml;ndig weiter und
          bem&uuml;hen uns korrekte und aktuelle Informationen bereitzustellen.
          Leider k&ouml;nnen wir keine Haftung f&uuml;r die Korrektheit aller
          Inhalte auf dieser Webseite &uuml;bernehmen, speziell f&uuml;r jene
          die seitens Dritter bereitgestellt wurden.
        </p>
        <p>
          Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen,
          bitten wir Sie uns umgehend zu kontaktieren, Sie finden die
          Kontaktdaten im Impressum.
        </p>
        <h2>Haftung f&uuml;r Links auf dieser Webseite</h2>
        <p>
          Unsere Webseite enth&auml;lt Links zu anderen Webseiten f&uuml;r deren
          Inhalt wir nicht verantwortlich sind. Haftung f&uuml;r verlinkte
          Websites besteht laut
          <a
            href="https://www.ris.bka.gv.at/Dokument.wxe?Abfrage=Bundesnormen&amp;Dokumentnummer=NOR40025813&amp;tid=221099081"
            target="_blank"
            rel="noopener noreferrer"
          >
            &sect; 17 ECG
          </a>
          f&uuml;r uns nicht, da wir keine Kenntnis rechtswidriger
          T&auml;tigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch
          bisher nicht aufgefallen sind und wir Links sofort entfernen
          w&uuml;rden, wenn uns Rechtswidrigkeiten bekannt werden.
        </p>
        <p>
          Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten
          wir Sie uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.
        </p>
        <h2>Urheberrechtshinweis</h2>
        <p>
          Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
          unterliegen dem Urheberrecht. Falls notwendig, werden wir die
          unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich
          verfolgen.
        </p>
        <h2>Bildernachweis</h2>
        <p>
          Die Bilder, Fotos und Grafiken auf dieser Webseite sind
          urheberrechtlich gesch&uuml;tzt.
        </p>
        <p>
          Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:
        </p>
        <ul>
          <li>Fotograf Mustermann</li>
        </ul>
        <h1>Datenschutzerkl&auml;rung</h1>
        <h2>Cookies</h2>
        <p>
          Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu
          speichern.
          <br />
          Im Folgenden erkl&auml;ren wir, was Cookies sind und warum Sie genutzt
          werden, damit Sie die folgende Datenschutzerkl&auml;rung besser
          verstehen.
        </p>
        <h3>Was genau sind Cookies?</h3>
        <p>
          Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser.
          Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet
          Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine
          Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
        </p>
        <p>
          Eines ist nicht von der Hand zu weisen: Cookies sind echt
          n&uuml;tzliche Helferlein. Fast alle Webseiten verwenden&nbsp;Cookies.
          Genauer gesprochen sind es HTTP-Cookies, da es auch noch anderer
          Cookies f&uuml;r andere Anwendungsbereiche gibt.
          HTTP-Cookies&nbsp;sind kleine Dateien, die von unserer Website auf
          Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden
          automatisch im Cookie-Ordner, quasi dem &bdquo;Hirn&ldquo; Ihres
          Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem
          Wert. Bei der Definition eines Cookies m&uuml;ssen zus&auml;tzlich ein
          oder mehrere Attribute angegeben werden.
        </p>
        <p>
          Cookies&nbsp;speichern gewisse Nutzerdaten von Ihnen, wie
          beispielsweise Sprache oder pers&ouml;nliche Seiteneinstellungen. Wenn
          Sie unsere Seite wieder aufrufen, &uuml;bermittelt Ihr Browser die
          &bdquo;userbezogenen&ldquo; Informationen an unsere Seite zur&uuml;ck.
          Dank der Cookies wei&szlig; unsere Website, wer Sie sind und bietet
          Ihnen Ihre gewohnte Standardeinstellung. In einigen Browsern hat
          jedes&nbsp;Cookie&nbsp;eine eigene Datei, in anderen wie
          beispielsweise Firefox sind alle&nbsp;Cookies&nbsp;in einer einzigen
          Datei gespeichert.
        </p>
        <p>
          Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies.
          Erstanbieter-Cookies werden direkt von unserer Seite erstellt,
          Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google
          Analytics) erstellt.&nbsp;Jedes Cookie ist individuell zu bewerten, da
          jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies
          variiert von ein paar Minuten bis hin zu ein paar Jahren.&nbsp;Cookies
          sind keine Software-Programme und enthalten keine Viren, Trojaner oder
          andere &bdquo;Sch&auml;dlinge&ldquo;. Cookies k&ouml;nnen auch nicht
          auf Informationen Ihres PCs zugreifen.
        </p>
        <p>So k&ouml;nnen zum Beispiel Cookie-Daten aussehen:</p>
        <ul>
          <li>Name: _ga</li>
          <li>Ablaufzeit: 2 Jahre</li>
          <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
          <li>Beispielhafter Wert:&nbsp;GA1.2.1326744211.152221099081</li>
        </ul>
        <p>
          Ein Browser sollte folgende Mindestgr&ouml;&szlig;en
          unterst&uuml;tzen:
        </p>
        <ul>
          <li>Ein Cookie soll mindestens 4096 Bytes enthalten k&ouml;nnen</li>
          <li>
            Pro Domain sollen mindestens 50 Cookies gespeichert werden
            k&ouml;nnen
          </li>
          <li>
            Insgesamt sollen mindestens 3000 Cookies gespeichert werden
            k&ouml;nnen
          </li>
        </ul>
        <h3>Welche Arten von Cookies gibt es?</h3>
        <p>
          Die Frage welche Cookies wir im Speziellen verwenden, h&auml;ngt von
          den verwendeten Diensten ab und wird in der folgenden Abschnitten der
          Datenschutzerkl&auml;rung gekl&auml;rt. An dieser Stelle m&ouml;chten
          wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.
        </p>
        <p>Man kann 4 Arten von Cookies unterscheiden:</p>
        <p>
          <strong>
            Unbedingt notwendige Cookies
            <br />
          </strong>
          Diese Cookies sind n&ouml;tig, um grundlegende Funktionen der Website
          sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User
          ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft
          und sp&auml;ter erst zur Kasse geht. Durch diese Cookies wird der
          Warenkorb nicht gel&ouml;scht, selbst wenn der User sein
          Browserfenster schlie&szlig;t.
        </p>
        <p>
          <strong>
            Funktionelle Cookies
            <br />
          </strong>
          Diese Cookies sammeln Infos &uuml;ber das Userverhalten und ob der
          User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser
          Cookies auch die Ladezeit und das Verhalten der Website bei
          verschiedenen Browsern gemessen.
        </p>
        <p>
          <strong>
            Zielorientierte Cookies
            <br />
          </strong>
          Diese Cookies sorgen f&uuml;r eine bessere Nutzerfreundlichkeit.
          Beispielsweise werden eingegebene Standorte, Schriftgr&ouml;&szlig;en
          oder Formulardaten gespeichert.
        </p>
        <p>
          <strong>
            Werbe-Cookies
            <br />
          </strong>
          Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu
          dem User individuell angepasste Werbung zu liefern. Das kann sehr
          praktisch, aber auch sehr nervig sein.
        </p>
        <p>
          &Uuml;blicherweise werden Sie beim erstmaligen Besuch einer Webseite
          gefragt, welche dieser Cookiearten Sie zulassen m&ouml;chten. Und
          nat&uuml;rlich wird diese Entscheidung auch in einem Cookie
          gespeichert.
        </p>
        <h3>Wie kann ich Cookies l&ouml;schen?</h3>
        <p>
          Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst.
          Unabh&auml;ngig von welchem Service oder welcher Website die Cookies
          stammen, haben Sie immer die M&ouml;glichkeit&nbsp;Cookies zu
          l&ouml;schen, nur teilweise zuzulassen oder zu deaktivieren. Zum
          Beispiel k&ouml;nnen Sie Cookies von Drittanbietern blockieren, aber
          alle anderen Cookies zulassen.
        </p>
        <p>
          Wenn Sie feststellen m&ouml;chten, welche Cookies in Ihrem Browser
          gespeichert wurden, wenn Sie Cookie-Einstellungen &auml;ndern oder
          l&ouml;schen wollen, k&ouml;nnen Sie dies in Ihren
          Browser-Einstellungen finden:
        </p>
        <p>
          <a
            href="https://support.google.com/chrome/answer/95647?tid=221099081"
            rel="noopener noreferrer"
          >
            Chrome: Cookies in Chrome l&ouml;schen, aktivieren und verwalten
          </a>
        </p>
        <p>
          <a
            href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=221099081"
            rel="noopener noreferrer"
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </p>
        <p>
          <a
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=221099081"
            rel="noopener noreferrer"
          >
            Firefox: Cookies l&ouml;schen, um Daten zu entfernen, die Websites
            auf Ihrem Computer abgelegt haben
          </a>
        </p>
        <p>
          <a
            href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=221099081"
            rel="noopener noreferrer"
          >
            Internet Explorer: L&ouml;schen und Verwalten von Cookies
          </a>
        </p>
        <p>
          <a
            href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=221099081"
            rel="noopener noreferrer"
          >
            Microsoft Edge: L&ouml;schen und Verwalten von Cookies
          </a>
        </p>
        <p>
          Falls Sie grunds&auml;tzlich keine Cookies haben wollen, k&ouml;nnen
          Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn
          ein Cookie gesetzt werden soll. So k&ouml;nnen Sie bei jedem einzelnen
          Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die
          Vorgangsweise ist je nach Browser verschieden. Am besten ist es Sie
          suchen die Anleitung in Google mit dem Suchbegriff &ldquo;Cookies
          l&ouml;schen Chrome&rdquo; oder &bdquo;Cookies deaktivieren
          Chrome&ldquo; im Falle eines Chrome Browsers oder tauschen das Wort
          &bdquo;Chrome&ldquo; gegen den Namen Ihres Browsers, z.B. Edge,
          Firefox, Safari aus.
        </p>
        <h3>Wie sieht es mit meinem Datenschutz aus?</h3>
        <p>
          Seit 2009 gibt es die sogenannten &bdquo;Cookie-Richtlinien&ldquo;.
          Darin ist festgehalten, dass das Speichern von Cookies eine
          Einwilligung des Website-Besuchers (also von Ihnen) verlangt.
          Innerhalb der EU-L&auml;nder gibt es allerdings noch sehr
          unterschiedliche Reaktionen auf diese Richtlinien. In &Ouml;sterreich
          erfolgte aber die Umsetzung dieser Richtlinie in &sect; 96 Abs. 3 des
          Telekommunikationsgesetzes (TKG).
        </p>
        <p>
          Wenn Sie mehr &uuml;ber Cookies wissen m&ouml;chten und vor
          technischen Dokumentationen nicht zur&uuml;ckscheuen, empfehlen
          wir&nbsp;
          <a
            href="https://tools.ietf.org/html/rfc6265"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.ietf.org/html/rfc6265
          </a>
          , dem Request for Comments der Internet Engineering Task Force (IETF)
          namens &bdquo;HTTP State Management Mechanism&ldquo;.
        </p>
        <h2>Rechte laut Datenschutzgrundverordnung</h2>
        <p>
          Ihnen stehen laut den Bestimmungen der DSGVO und des
          &ouml;sterreichischen
          <a
            href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=10001597&amp;tid=221099081"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutzgesetzes (DSG)
          </a>
          grunds&auml;tzlich die folgende Rechte zu:
        </p>
        <ul>
          <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
          <li>
            Recht auf L&ouml;schung (&bdquo;Recht auf Vergessenwerden&ldquo;)
            (Artikel 17 DSGVO)
          </li>
          <li>
            Recht auf Einschr&auml;nkung der Verarbeitung (Artikel 18 DSGVO)
          </li>
          <li>
            Recht auf Benachrichtigung &ndash; Mitteilungspflicht im
            Zusammenhang mit der Berichtigung oder L&ouml;schung
            personenbezogener Daten oder der Einschr&auml;nkung der Verarbeitung
            (Artikel 19 DSGVO)
          </li>
          <li>Recht auf Daten&uuml;bertragbarkeit (Artikel 20 DSGVO)</li>
          <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
          <li>
            Recht, nicht einer ausschlie&szlig;lich auf einer automatisierten
            Verarbeitung &mdash; einschlie&szlig;lich Profiling &mdash;
            beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
          </li>
        </ul>
        <p>
          Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
          Datenschutzrecht verst&ouml;&szlig;t oder Ihre datenschutzrechtlichen
          Anspr&uuml;che sonst in einer Weise verletzt worden sind, k&ouml;nnen
          Sie sich bei der Aufsichtsbeh&ouml;rde beschweren, welche in
          &Ouml;sterreich die Datenschutzbeh&ouml;rde ist, deren Webseite Sie
          unter
          <a
            href="https://www.dsb.gv.at/?tid=221099081"
            rel="noopener noreferrer"
          >
            https://www.dsb.gv.at/
          </a>
          finden.
        </p>
        <h2>Datenschutz</h2>
        <p>
          Wir haben diese Datenschutzerkl&auml;rung (Fassung
          01.08.2019-221099081) verfasst, um Ihnen gem&auml;&szlig; der Vorgaben
          der Datenschutz-Grundverordnung (EU) 2016/679 und dem
          <a
            href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=10001597&amp;tid=221099081"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutzgesetz (DSG)
          </a>
          zu erkl&auml;ren, welche Informationen wir sammeln, wie wir Daten
          verwenden und welche Entscheidungsm&ouml;glichkeiten Sie als Besucher
          dieser Webseite haben.
        </p>
        <p>
          Leider liegt es in der Natur der Sache, dass diese Erkl&auml;rungen
          sehr technisch klingen. Wir haben uns bei der Erstellung jedoch
          bem&uuml;ht die wichtigsten Dinge so einfach und klar wie m&ouml;glich
          zu beschreiben.
        </p>
        <h2>Automatische Datenspeicherung</h2>
        <p>
          Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen
          automatisch erstellt und gespeichert, so auch auf dieser Webseite.
        </p>
        <p>
          Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser
          Webserver (Computer auf dem diese Webseite gespeichert ist)
          automatisch Daten wie
        </p>
        <ul>
          <li>die Adresse (URL) der aufgerufenen Webseite</li>
          <li>Browser und Browserversion</li>
          <li>das verwendete Betriebssystem</li>
          <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
          <li>
            den Hostname und die IP-Adresse des Ger&auml;ts von welchem aus
            zugegriffen wird
          </li>
          <li>Datum und Uhrzeit</li>
        </ul>
        <p>in Dateien (Webserver-Logfiles).</p>
        <p>
          In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und
          danach automatisch gel&ouml;scht. Wir geben diese Daten nicht weiter,
          k&ouml;nnen jedoch nicht ausschlie&szlig;en, dass diese Daten beim
          Vorliegen von rechtswidrigem Verhalten eingesehen werden.
        </p>
        <p>
          Quelle: Erstellt&nbsp;mit dem&nbsp;
          <a
            title="Datenschutz Generator von firmenwebseiten.at"
            href="https://www.firmenwebseiten.at/datenschutz-generator/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutz Generator von firmenwebseiten.at
          </a>
          &nbsp;in Kooperation mit
          <a
            title=""
            href="https://www.bauguide.at"
            target="_blank"
            rel="noopener noreferrer"
          >
            bauguide.at
          </a>
        </p>
      </div>
    </div>
  )
}

export default Impressum
