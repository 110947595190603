import React, { useState } from "react"
import anime from "animejs"

import Hamburger from "./hamburger"
import SideMenu from "./side-menu"
import LogoBlack from "../images/logo-black-name.svg"

const Navbar = () => {
  const [menuOpen, toggleMenuOpen] = useState(false)
  const paths = {
    menuPath:
      "M29.9,1.2H0.4 M38.6,14.3H9.1 M38.6,7.8H0.4 M38.6,1.2c0-0.4-0.4-0.8-0.8-0.8S37,0.8,37,1.2S37.4,2,37.8,2S38.6,1.7,38.6,1.2z M2,14.3c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8S2,14.7,2,14.3z",
    closePath:
      "M27.6,0.3L6.8,21.2 M0.4,0.4l27,27 M1.8,26.2c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1s0.8,0.3,1.1,0S2.1,26.5,1.8,26.2z",
  }
  let activeClass = menuOpen ? "active" : ""

  const handleMenu = e => {
    const timeline = anime.timeline({
      duration: 150,
      easing: "easeOutQuad",
    })

    timeline.add({
      targets: ".menu-button",
      d: [
        {
          value: !menuOpen ? paths.closePath : paths.menuPath,
        },
      ],
    })

    timeline.add({
      targets: ".line-dot path",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeOutQuad",
      duration: 300,
    })

    toggleMenuOpen(!menuOpen)
  }
  return (
    <>
      <nav>
        <div>
          <img className="nav-logo" src={LogoBlack} alt="" />{" "}
        </div>
        <div className="open-menu" onClick={handleMenu}>
          <Hamburger paths={paths} />
        </div>
        <SideMenu activeClass={activeClass} handleMenu={handleMenu} />
      </nav>
      <div
        className={"click-overlay " + activeClass}
        onClick={handleMenu}
      ></div>
    </>
  )
}

export default Navbar
