import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import About from "../components/about"
import Work from "../components/work"
import Referenzen from "../components/referenzen"
import Footer from "../components/footer"
import CookieConsent from "react-cookie-consent"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />

      <CookieConsent
        buttonClasses="cookies-button"
        buttonText="Ich stimme zu."
        style={{
          background: "#3c3c3b",
        }}
      >
        Diese Webseite verwendet Cookies.
      </CookieConsent>
      <Hero />
      <Work />
      <About />
      <Referenzen />
      <Footer />
    </Layout>
  )
}

export default IndexPage
