import React, { useEffect } from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, StaticQuery } from "gatsby"

import Vivus from "vivus"

const Hero = () => {
  useEffect(() => {
    new Vivus("hero-logo", { duration: 100, animTimingFunction: Vivus.EASE })
  })
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "header.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <div className="hero-wrapper">
          <BackgroundImage
            fluid={data.file.childImageSharp.fluid}
            className="hero-background"
          >
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="102.1px"
              height="170.6px"
              viewBox="0 0 102.1 170.6"
              className="hero-logo wow zoomIn"
              id="hero-logo"
            >
              <path
                className="st0"
                d="M78.7,95.7c8.1,7.4,13.2,18.1,13.2,30c0,22.5-18.1,40.7-40.5,40.7S11,148.2,11,125.7c0,0,0-16.9,0-23.6
	c0-6.9-8.3-13.8-8.3-13.8 M99.4,81.8c0,0-8.3-6.9-8.3-13.8c0-6.8,0-23.1,0-23.1C91.2,22.4,73,4.2,50.7,4.2S10.2,22.4,10.2,44.9
	s18.1,40.7,40.5,40.7c17.4,0,28.2,10.3,28.2,10.3"
              />
            </svg>
            <h1 className="hero-title wow zoomIn">übersetzerwerkstatt</h1>
            <div className="hero-circle"></div>
          </BackgroundImage>
        </div>
      )}
    />
  )
}

export default Hero
