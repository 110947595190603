import React, { useEffect } from "react"
import PropTypes from "prop-types"

import AOS from "aos"

import "aos/dist/aos.css"

import Navbar from "./navbar"

import "../scss/main.scss"

const Layout = ({ children }) => {
  useEffect(() => {
    AOS.init({
      duration: 300,
      offset: 100,
      easing: "ease-in-quad",
    })
  })
  return (
    <>
      <Navbar />
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
