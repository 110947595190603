import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "portrait.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section id="about">
      <div className="content-grid content-grid-about">
        <div className="content-item about-1">
          <h2 className="content-title" data-aos="fade-up">
            Über mich
          </h2>
        </div>
        <div className="content-item about-2">
          <h3 className="content-title" data-aos="fade-up">
            mag. phil. Alexandra Unterasinger
          </h3>
        </div>
        <div className="content-item about-3" data-aos="fade-up">
          <Img fluid={data.file.childImageSharp.fluid} />
        </div>
        <div className="content-item about-4">
          <p className="content-paragraph" data-aos="fade-up">
            Aus meiner Leidenschaft für Sprache(n), Texte und geschriebene Worte
            ist ein Beruf geworden. Ich habe Translationswissenschaften an der
            KFUNI Graz studiert und bin Mitglied von UNIVERSITAS, dem
            Berufsverband für Übersetzer und Dolmetscher in Österreich.
          </p>
        </div>
        <div className="content-line"></div>
      </div>
    </section>
  )
}

export default About
