import React from "react"

const SideMenu = ({ activeClass, handleMenu }) => {
  const handleLink = e => {
    handleMenu()
  }

  const menuItems = [
    {
      id: 1,
      title: "home",
      target: "top",
    },
    {
      id: 2,
      title: "was ich anbiete",
      target: "leistungen",
    },
    {
      id: 3,
      title: "über mich",
      target: "about",
    },
    {
      id: 4,
      title: "referenzen",
      target: "referenzen",
    },
    {
      id: 5,
      title: "kontakt",
      target: "kontakt",
    },
  ]

  const menu = menuItems.map(item => (
    <a href={"#" + item.target} key={item.id} onClick={handleLink}>
      <li>{item.title}</li>
    </a>
  ))

  return (
    <aside className={"sidemenu-wrapper " + activeClass}>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        width="429.3px"
        height="8px"
        className="line-dot"
        viewBox="0 0 429.3 8"
      >
        <path
          className="st0"
          d="M32.4,4h396.8 M1,4c0,1.7,1.3,3,3,3s3-1.3,3-3S5.7,1,4,1S1,2.3,1,4z"
        />
      </svg>{" "}
      <ul>{menu}</ul>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        width="429.3px"
        height="8px"
        className="line-dot line-dot-reverse"
        viewBox="0 0 429.3 8"
      >
        <path
          className="st0"
          d="M32.4,4h396.8 M1,4c0,1.7,1.3,3,3,3s3-1.3,3-3S5.7,1,4,1S1,2.3,1,4z"
        />
      </svg>{" "}
    </aside>
  )
}

export default SideMenu
